import React from "react";
import {
  IconSVG,
  mapping,
  Pill,
  ButtonSolid,
  Risk_Matrix_Compoenet_Block,
  Tab_BookMark,
  DropDownListButton,
  NameCard,
  FormatDate,
  AutoSizeTextArea,
} from "@aim-mf/styleguide";

const VersionButton = {
  width: "13.5rem",
  height: "2.75rem",
  backgroundColor: "#3D4657",
  borderRadius: "20.5px",
  display: "flex",
  position: "relative",
  zIndex: 1,
  marginBottom: "0.8rem",
  outline: "none",
};

const VersionButtonHover = {
  width: "13.5rem",
  height: "2.75rem",
  backgroundColor: "#3D4657",
  borderRadius: "20.5px",
  display: "flex",
  position: "relative",
  zIndex: 1,
  marginBottom: "0.8rem",
  border: "2px solid #00A2FF",
  outline: "none",
};
const SendButton = {
  color: "white",
  "background-color": "#6C757D",
  "border-radius": "5px",
  padding: "2px 8px 2px 8px",
  "font-size": 10,
};

class ControlDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      control: this.props.control,
    };
  }

  render() {
    return (
      <div style={ControlDetailWrapperStyle}>
        <div
          style={{
            ...mapping["heading/h5/lightleft"],
            padding: "17px 0 0 17px",
          }}
        >
          Control Details
        </div>
        <div style={ControlDetailDropDownsWrapperStyle}>
          <div style={DropDownContentStyle}>
            <div style={sectionTitleStyle}>Control Type</div>
            <DropDownListButton
              value={this.props.controlDetail.CSA_type}
              data={this.props.controlDetailDropdownlistItem.type}
              CustomOnChange={(event) => {
                this.props.handleControlDetailChange("CSA_type", event.value);
              }}
              DropDownButtonStyle={riskTierDropDownButtonStyle}
            />
          </div>
          <div style={DropDownContentStyle}>
            <div style={sectionTitleStyle}>Antifraud</div>
            <DropDownListButton
              value={this.props.controlDetail.antifraud}
              data={this.props.controlDetailDropdownlistItem.antifraud}
              CustomOnChange={(event) => {
                this.props.handleControlDetailChange("antifraud", event.value);
              }}
              DropDownButtonStyle={riskTierDropDownButtonStyle}
            />
          </div>
          <div style={DropDownContentStyle}>
            <div style={sectionTitleStyle}>Manual/System control</div>
            <DropDownListButton
              value={this.props.controlDetail.manual_system}
              data={this.props.controlDetailDropdownlistItem.manual_system}
              CustomOnChange={(event) => {
                this.props.handleControlDetailChange(
                  "manual_system",
                  event.value
                );
              }}
              DropDownButtonStyle={riskTierDropDownButtonStyle}
            />
          </div>
        </div>
      </div>
    );
  }

  handledelegateChange = () => {
    this.props.handleAddUser("delegate", -1, this.props.riskDetail.delegate);
  };
}

const ControlDetailWrapperStyle = {
  width: "100%",
  height: "fit-content",
  marginTop: "1rem",
  marginLeft: "5rem",
  backgroundColor: mapping["Color_BG_03_trans"],
};

const ControlDetailDropDownsWrapperStyle = {
  width: "65.6rem",
  height: "fit-content",
  margin: "1.5rem",
  backgroundColor: "rgba(62,78,108,0.5)",
  display: "inline-flex",
};

const DropDownContentStyle = {
  width: "17rem",
  padding: "1rem 2.2rem 1rem 1rem",
};
const ShortTextStyle = {
  ...mapping["forms/input-small"],
  overflow: "hidden",
  height: "2rem",
  border: "none",
  //outline:mapping['Field_Outline']+' solid '+borderColor,
  padding: "9px 0 0 13px",
  borderRadius: 0,
  width: "9rem",
  borderWidth: "0px",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  color: mapping["Color_Basic_Light"],
};

const AttachmentsTitleStyle = {
  height: "17px",
  width: "76px",
  color: "#FFFFFF",
  fontFamily: "Open Sans",
  fontSize: "12px",
  fontWeight: "bold",
  letterSpacing: "-0.23px",
  lineHeight: "17px",
};

const ShortMultilineInputFieldStyle = {
  resize: "none",
  overflow: "hidden",
  height: "auto",
  border: "none",
  //outline:mapping['Field_Outline']+' solid '+borderColor,
  paddingLeft: mapping["Field_padding_Horizontal"],
  paddingRight: mapping["Field_padding_Horizontal"],
  borderRadius: 0,
  width: "20.875rem",
  borderWidth: "0px",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  color: mapping["Color_Basic_Light"],
};

const parentRiskStyle = {
  paddingTop: "0.2rem",
  ...mapping["symbols/breadcrumbs/active"],
  color: "#6C757D",
};

const riskStatusStyle = {
  paddingTop: "1.25rem",
};

const riskNameStyle = {
  paddingTop: "1rem",
  ...mapping["heading/h3/lightleft"],
};

const riskInfoSectionWrapperStyle = {
  width: "100%",
  height: "15rem",
  display: "inline-flex",
};

const descriptionWrapperStyle = {
  width: "25rem",
  height: "100%",
};
const riskOwnerWrapperStyle = {
  width: "15rem",
  height: "100%",
};
const riskDelegateWrapperStyle = {
  width: "15rem",
  height: "100%",
};
const riskRatingWrapperStyle = {
  width: "15rem",
  height: "15rem",
};
const riskVersionWrapperStyle = {
  width: "25rem",
  height: "100%",
  fontSize: 0,
  position: "relative",
};
const sectionTitleStyle = {
  ...mapping["Sub Text Tag/H7-blue-tag"],
  color: "#00BEFF",
  paddingBottom: "1.25rem",
};
const descriptionContentStyle = {
  ...mapping["paragraph/small/lightleft"],
};
const riskOwnerContentStyle = {
  display: "inline-flex",
  paddingBottom: "1.25rem",
};

const riskOwnerRoleStyle = {
  ...mapping["Font_Avatar_Role"],
  paddingTop: "0.2rem",
};

const riskRatingContentStyle = {
  transform: "scale(0.4)",
  transformOrigin: "top left",
  paddingTop: "1rem",
};

const RiskTIerWrapperStyle = {
  width: "25rem",
  height: "fit-content",
  paddingBottom: "3rem",
};

const riskTierDropDownButtonStyle = {
  width: "100%",
  height: "2rem",
  backgroundColor: mapping["Color_Basic_Secondary"],
  border: "none",
  boxShadow: "none",
};
const versionButtonContentStyle = {
  display: "inline-flex",
  width: "fit-content",
  margin: "auto",
  height: "fit-content",
};
const versionButtonLeftIconStyle = {
  backgroundColor: mapping["Color_Basic_Secondary"],
  borderRadius: "50%",
  width: "2.375rem",
  height: "2.375rem",
};
const versionButtonNameStyle = {
  textAlign: "left",
  ...mapping["Font_Version_Name"],
  lineHeight: "17px",
};
const versionButtonDateStyle = {
  ...mapping["Font_Version_Date"],
  width: "50%",
  textAlign: "left",
  display: "inline-flex",
};
const versionButtonStatusStyle = {
  ...mapping["Font_Version_Status"],
  width: "50%",
  textAlign: "left",
  display: "inline-flex",
};
const versionDotLineStyle = {
  top: "4rem",
  marginLeft: "22.5px",
  height: "14rem",
  display: "inline-block",
  borderStyle: "dashed",
  borderLeftWidth: "1px",
  zIndex: "0",
  position: "absolute",
  borderColor: "rgba(181,198,209,0.5)",
};
const versionButtonViewMoreWrapperStyle = {
  marginLeft: "18px",
  fontSize: 0,
  position: "absolute",
  top: "18rem",
};
const versionDotCircleStyle = {
  height: "10px",
  width: "10px",
  backgroundColor: "283244",
  borderRadius: "50%",
  border: "solid",
  borderColor: "#6C7A88",
  display: "inline-block",
};
const versionButtonViewMoreStyle = {
  padding: "0px",
  textAlign: "top",
  marginLeft: 6,
  marginBottom: 0,
  position: "relative",
  top: -3,
  zIndex: 2,
  display: "inline-block",
  textDecoration: "underline",
  outline: "none",
  height: "16px",
  width: "57px",
  color: "#00BEFF",
  fontFamily: "Roboto",
  fontSize: "12px",
  fontWeight: 500,
  letterSpacing: 0,
  lineHheight: "16px",
};
export { ControlDetail };
