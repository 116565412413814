import React from "react";
import {
  IconSVG,
  mapping,
  AutoSizeTextArea,
  NonValidationInput,
  CustomDatePicker,
  CustomizedCheckBox,
  IconButtonSolid,
} from "@aim-mf/styleguide";

class ControlSelfAssessmentDocumentTestResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={ControlDetailDropDownsWrapperStyle}>
        <div style={{ display: "inline-flex" }}>
          <TestTableHeader />
        </div>
        <div>
          {this.props.testResult
            .sort((a, b) => {
              return a.id > b.id ? 1 : 0;
            })
            .map((testResult, index) => {
              return (
                <TestTableContent
                  testResult={testResult}
                  index={index}
                  handleTestResultChange={this.handleTestResultChange}
                  handleTestResultDelete={this.handleTestResultDelete}
                  handleRemarkOpen={this.handleRemarkOpen}
                />
              );
            })}
          <div
            style={{
              height: "1.25rem",
              marginLeft: "1.25rem",
              marginRight: "1.3rem",
              backgroundColor: mapping["Color_BG_02_solid"],
            }}
          />
          <AddNew handleTestResultAddNew={this.handleTestResultAddNew} />
        </div>
      </div>
    );
  }

  handleRemarkOpen = (index) => {
    this.props.handleRemarkOpen(this.props.documentIndex, index);
  };

  handleTestResultAddNew = () => {
    let newTestResult = this.props.testResult;
    newTestResult.push({
      id: Math.max(...newTestResult.map((a) => a.id), 0) + 1,
      access_change_request: false,
      access_rights_review: false,
      date: new Date(),
      description: "",
      periodic_log_review: false,
      remarks: "",
      is_new: true,
    });
    this.props.handleDocumentTestResultChange(
      this.props.documentIndex,
      newTestResult
    );
  };

  handleTestResultChange = (index, key, value) => {
    let newTestResult = this.props.testResult;
    newTestResult[index][key] = value;
    this.props.handleDocumentTestResultChange(
      this.props.documentIndex,
      newTestResult
    );
  };

  handleTestResultDelete = (index) => {
    let newTestResult = this.props.testResult;
    newTestResult.splice(index, 1);
    this.props.handleDocumentTestResultChange(
      this.props.documentIndex,
      newTestResult
    );
  };
}

const TestTableHeader = (props) => {
  return (
    <div
      style={{
        display: "inline-flex",
        alignItems: "flex-end",
        paddingLeft: "30px",
      }}
    >
      <div style={{ ...sectionTitleStyle, width: "57px" }}>S/N</div>
      <div style={{ ...sectionTitleStyle, width: "166px" }}>
        Date of sample document
      </div>
      <div
        style={{ ...sectionTitleStyle, width: "230px", paddingRight: "40px" }}
      >
        document description and reference
      </div>
      <div style={{ ...sectionTitleStyle, width: "144px" }}>
        evidence of access rights review
      </div>
      <div style={{ ...sectionTitleStyle, width: "144px" }}>
        Approved user access change request
      </div>
      <div style={{ ...sectionTitleStyle, width: "154px" }}>
        evidence of periodic log review
      </div>
      <div style={{ ...sectionTitleStyle, width: "85px" }}>Remarks</div>
    </div>
  );
};

const TestTableContent = (props) => {
  return (
    <div style={testResultContentWrapperStyle}>
      <div
        className={"div_hover"}
        style={{ position: "absolute", top: "25px", right: "2px" }}
      >
        <IconSVG
          name={"delete"}
          color={mapping["Color_Basic_Mute_2"]}
          onClick={() => {
            props.handleTestResultDelete(props.index);
          }}
        />
      </div>
      <div style={{ ...TestResultContentStyle, width: "47px" }}>
        {props.index + 1}
      </div>
      <div style={{ ...TestResultContentStyle, width: "166px" }}>
        <DateOfSampleDocument
          index={props.index}
          handleTestResultChange={props.handleTestResultChange}
          value={props.testResult.date}
        />
      </div>
      <div style={{ ...TestResultContentStyle, width: "230px" }}>
        <DocumentDescription
          index={props.index}
          description={props.testResult.description}
          handleTestResultChange={props.handleTestResultChange}
        />
      </div>
      <div style={{ ...TestResultContentStyle, width: "144px" }}>
        <AccessRightsReview
          index={props.index}
          value={props.testResult.access_rights_review}
          handleTestResultChange={props.handleTestResultChange}
        />
      </div>
      <div style={{ ...TestResultContentStyle, width: "144px" }}>
        <AccessChangeRequest
          index={props.index}
          value={props.testResult.access_change_request}
          handleTestResultChange={props.handleTestResultChange}
        />
      </div>
      <div style={{ ...TestResultContentStyle, width: "144px" }}>
        <PeriodicLogReview
          index={props.index}
          value={props.testResult.periodic_log_review}
          handleTestResultChange={props.handleTestResultChange}
        />
      </div>
      <div
        className={"div_hover"}
        style={{
          display: "flex",
          justifyContent: "center",
          width: "90px",
          marginRight: "25px",
        }}
      >
        <Remarks
          index={props.index}
          handleRemarkOpen={props.handleRemarkOpen}
        />
      </div>
    </div>
  );
};

const DateOfSampleDocument = (props) => {
  const CustomOnChange = (value) => {
    props.handleTestResultChange(props.index, "date", value);
  };
  return (
    <CustomDatePicker
      width={145}
      index={props.index}
      CustomOnChange={CustomOnChange}
      value={props.value}
    />
  );
};

const DocumentDescription = (props) => {
  const CustomOnChange = (event) => {
    props.handleTestResultChange(props.index, "description", event.value);
  };
  return (
    <TextInput
      width={"210px"}
      onCustomChange={CustomOnChange}
      value={props.description}
      index={props.index}
    />
  );
};

const AccessRightsReview = (props) => {
  const onAccessRightsReviewToggle = () => {
    props.handleTestResultChange(
      props.index,
      "access_rights_review",
      !props.value
    );
  };
  return (
    <CustomizedCheckBox
      value={props.value}
      checkBoxStyle={{
        backgroundColor: props.value ? "#00BEFF" : "rgba(255,255,255,0.11)",
      }}
      onCheckChange={onAccessRightsReviewToggle}
    />
  );
};

const AccessChangeRequest = (props) => {
  const onAccessRightsReviewToggle = () => {
    props.handleTestResultChange(
      props.index,
      "access_change_request",
      !props.value
    );
  };
  return (
    <CustomizedCheckBox
      value={props.value}
      checkBoxStyle={{
        backgroundColor: props.value ? "#00BEFF" : "rgba(255,255,255,0.11)",
      }}
      onCheckChange={onAccessRightsReviewToggle}
    />
  );
};

const PeriodicLogReview = (props) => {
  const onAccessRightsReviewToggle = () => {
    props.handleTestResultChange(
      props.index,
      "periodic_log_review",
      !props.value
    );
  };
  return (
    <CustomizedCheckBox
      value={props.value}
      checkBoxStyle={{
        backgroundColor: props.value ? "#00BEFF" : "rgba(255,255,255,0.11)",
      }}
      onCheckChange={onAccessRightsReviewToggle}
    />
  );
};

const Remarks = (props) => {
  const handleRemarkClick = () => {
    props.handleRemarkOpen(props.index);
  };
  return (
    <IconSVG
      name={"comment"}
      color={mapping["Color_Basic_Primary"]}
      onClick={handleRemarkClick}
    />
  );
};

const AddNew = (props) => {
  const onClick = () => {
    props.handleTestResultAddNew();
  };
  return (
    <div
      style={{
        display: "flex",
        backgroundColor: mapping["Color_BG_02_solid"],
        margin: "6px 20px 0 20px",
        padding: "16px",
      }}
    >
      <div style={{ margin: "auto" }}>
        <IconButtonSolid
          name={"Add"}
          icon={
            <IconSVG
              name={"newRisk"}
              color={"white"}
              size={"0.6"}
              wrapperStyle={{
                transformOrigin: "0 50% 0px",
              }}
            />
          }
          width={"64px"}
          height={"31px"}
          iconPos={"Left"}
          size={"Small"}
          clickEvent={onClick}
        />
      </div>
    </div>
  );
};

const TextInput = (props) => {
  return (
    <NonValidationInput
      fieldstyle={{
        ...InputFieldStyle,
        width: props.width ? props.width : "210px",
      }}
      value={props.value}
      onChange={props.onCustomChange}
    />
  );
};

const sectionTitleStyle = {
  ...mapping["Sub Text Tag/H7-blue-tag"],
  color: "#00BEFF",
  paddingBottom: "1.25rem",
};

const TestResultContentStyle = {
  ...mapping["forms/input-small"],
};

const testResultContentWrapperStyle = {
  position: "relative",
  display: "inline-flex",
  alignItems: "center",
  padding: "20px 0 0 20px",
  marginLeft: "20px",
  backgroundColor: mapping["Color_BG_02_solid"],
};

const InputFieldStyle = {
  height: "36px",
  width: "210px",
  borderWidth: "0px",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  border: "none !important",
  color: mapping["Color_Basic_Light"],
  padding: "4px",
  resize: "none",
  overflow: "hidden",
  //outline:mapping['Field_Outline']+' solid '+borderColor,
  paddingLeft: mapping["Field_padding_Horizontal"],
  paddingRight: mapping["Field_padding_Horizontal"],
  borderRadius: 0,
  outline: "none",
};


const ControlDetailDropDownsWrapperStyle = {
  width: "65.6rem",
  height: "fit-content",
  margin: "0 1.5rem 0 1.5rem",
  backgroundColor: mapping["Color_BG_05_solid"],
};

export { ControlSelfAssessmentDocumentTestResults };
