import React from "react";
import {
  IconSVG,
  mapping,
  Pill,
  BottonSolid,
  ButtonSolid,
  Risk_Matrix_Compoenet_Block,
  Tab_BookMark,
  DropDownListButton,
  NameCard,
  FormatDate,
  AutoSizeTextArea,
} from "@aim-mf/styleguide";
import { ControlSelfAssessmentDocument } from "./CSADocument";

const VersionButton = {
  width: "13.5rem",
  height: "2.75rem",
  backgroundColor: "#3D4657",
  borderRadius: "20.5px",
  display: "flex",
  position: "relative",
  zIndex: 1,
  marginBottom: "0.8rem",
  outline: "none",
};

const VersionButtonHover = {
  width: "13.5rem",
  height: "2.75rem",
  backgroundColor: "#3D4657",
  borderRadius: "20.5px",
  display: "flex",
  position: "relative",
  zIndex: 1,
  marginBottom: "0.8rem",
  border: "2px solid #00A2FF",
  outline: "none",
};
const SendButton = {
  color: "white",
  "background-color": "#6C757D",
  "border-radius": "5px",
  padding: "2px 8px 2px 8px",
  "font-size": 10,
};

class ControlBasicInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      control: this.props.control,
    };
  }

  render() {
    const RiskStatus = "Working Draft";
    const RiskStatusBGColor = "#FDD60066";

    return (
      <div style={BasicInfoStyle}>
        <div
          style={{
            ...mapping["heading/h3/lightleft"],
            paddingTop: "4rem",
            paddingBottom: "3rem",
          }}
        >
          {this.props.control.description}
        </div>
        <div style={{ display: "inline-flex" }}>
          <div>
            <ControlOwner owner={this.props.control.owner} />
            <div style={{ height: "3rem" }} />
            <ControlFrequency frequency={this.props.control.frequency} />
          </div>
          <div style={{ width: "8.5rem" }} />
          <div>
            <RefPolicy
              policy={this.props.refPolicy}
              handleRefPolicyChange={this.props.handleRefPolicyChange}
            />
            <div style={{ height: "1.6rem" }} />
            <Attachment
              attachments={this.props.attachment}
              uploadingFile={this.props.uploadingFile}
              handleAttachmentDownload={this.props.handleAttachmentDownload}
              handleAttachmentChange={this.handleAttachmentChange}
              handleRemoveUploadFile={this.props.handleRemoveUploadFile}
              handleRemoveFile={this.props.handleRemoveFile}
            />
          </div>
        </div>
      </div>
    );
  }
  handleAttachmentChange = (e) => {
    if (e.target.files.length) {
      this.props.handleUploadFile(e.target.files[0]);
    }
  };
}

const ControlOwner = (props) => {
  return (
    <div>
      <div style={sectionTitleStyle}>Control Owner</div>
      {props.owner.map((owner) => {
        return (
          <NameCard
            initial={owner.display_name
              .split(" ")
              .map((a) => a[0])
              .join("")}
            name={owner.display_name}
            title={owner.title}
          />
        );
      })}
    </div>
  );
};

const ControlFrequency = (props) => {
  return (
    <div>
      <div style={sectionTitleStyle}>Frequency</div>
      <div style={ShortTextStyle}>{props.frequency}</div>
    </div>
  );
};

const RefPolicy = (props) => {
  return (
    <div>
      <div style={sectionTitleStyle}>Reference to policies and procedures</div>
      <AutoSizeTextArea
        fieldstyle={ShortMultilineInputFieldStyle}
        value={props.policy}
        CustomizedOnChange={props.handleRefPolicyChange}
      />
    </div>
  );
};

const Attachment = (props) => {
  //console.log(props.attachments);
  return (
    <div>
      <div style={sectionTitleStyle}>Attachments</div>
      {props.attachments.map((attachment, index) => {
        return (
          <div key={index}>
            <div style={AttachmentWrapper}>
              <IconSVG name={"file"} color={mapping["Color_Basic_Link"]} />
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <div
                style={AttachmentStyle}
                onClick={() => {
                  props.handleAttachmentDownload(
                    attachment.id,
                    attachment.filename
                  );
                }}
              >
                {attachment.filename}
              </div>
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <div
                className={"div_hover"}
                style={AttachmentDeleteButtonWrapperStyle}
                onClick={() => {
                  props.handleRemoveFile(attachment.id);
                }}
              >
                <IconSVG name={"delete"} size={"0.8"} color={"#eeeeee"} />
              </div>
            </div>
          </div>
        );
      })}
      {props.uploadingFile.map((attachment, index) => {
        return (
          <div key={index}>
            <div style={AttachmentWrapper}>
              <IconSVG name={"file"} color={mapping["Color_Basic_Link"]} />
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <div
                style={AttachmentStyle}
                // onClick={() => {
                //   props.handleAttachmentDownload(
                //       attachment.id,
                //       attachment.filename
                //   );
                // }}
              >
                {attachment.name}
              </div>
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <div
                className={"div_hover"}
                style={AttachmentDeleteButtonWrapperStyle}
                onClick={() => {
                  props.handleRemoveUploadFile(index);
                }}
              >
                <IconSVG name={"delete"} size={"0.8"} color={"#eeeeee"} />
              </div>
            </div>
          </div>
        );
      })}

      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          marginTop: "5px",
        }}
      >
        <input
          id="contained-button-file"
          multiple
          type="file"
          style={{ display: "none" }}
          onChange={props.handleAttachmentChange}
        />
        <label htmlFor="contained-button-file">
          <div style={fakeButtonStyle}>Add +</div>
        </label>
      </div>
    </div>
  );
};

const AttachmentWrapper = {
  paddingLeft: "27px",
  marginTop: "5px",
  marginBottom: "5px",
  display: "inline-flex",
  alignItems: "center",
  height: "40px",
  width: "353px",
  borderRadius: "24px",
  backgroundColor: mapping["Color_BG_03_trans"],
};

const AttachmentStyle = {
  paddingLeft: "13px",
  height: "22px",
  width: "214px",
  fontFamily: "Roboto",
  fontSize: "16px",
  letterSpacing: "0.2px",
  lineHeight: "22px",
  color: mapping["Color_Basic_Info"],
  textDecoration: "underline",
};

const AttachmentDeleteButtonWrapperStyle = {
  margin: "auto",
  marginRight: "23px",
  height: "24px",
  width: "24px",
  borderRadius: "3.1px",
  backgroundColor: mapping["Color_Basic_Primary"],
};

const BasicInfoStyle = {
  width: "100%",
  height: "fit-content",
  paddingTop: "1rem",
  paddingLeft: "5rem",
};

const ShortTextStyle = {
  ...mapping["forms/input-small"],
  overflow: "hidden",
  height: "2rem",
  border: "none",
  //outline:mapping['Field_Outline']+' solid '+borderColor,
  padding: "9px 0 0 13px",
  borderRadius: 0,
  width: "9rem",
  borderWidth: "0px",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  color: mapping["Color_Basic_Light"],
};

const AttachmentsTitleStyle = {
  height: "17px",
  width: "76px",
  color: "#FFFFFF",
  fontFamily: "Open Sans",
  fontSize: "12px",
  fontWeight: "bold",
  letterSpacing: "-0.23px",
  lineHeight: "17px",
};

const ShortMultilineInputFieldStyle = {
  resize: "none",
  overflow: "hidden",
  height: "auto",
  border: "none",
  //outline:mapping['Field_Outline']+' solid '+borderColor,
  paddingLeft: mapping["Field_padding_Horizontal"],
  paddingRight: mapping["Field_padding_Horizontal"],
  borderRadius: 0,
  width: "20.875rem",
  borderWidth: "0px",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  color: mapping["Color_Basic_Light"],
};

const sectionTitleStyle = {
  ...mapping["Sub Text Tag/H7-blue-tag"],
  color: "#00BEFF",
  paddingBottom: "1.25rem",
};

const fakeButtonStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "4px",
  ...mapping["Font_Button_Small_Primary"],
  width: "58px",
  height: "22px",
  color: "white",
  backgroundColor: mapping["Color_Basic_Primary"],
};
export { ControlBasicInfo };
