import React from "react";
import { mapping, ButtonSolid, AutoSizeTextArea } from "@aim-mf/styleguide";

class Remarks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: this.props.content,
    };
  }

  render() {
    return (
      <div style={remarkFormWrapStyle}>
        <div style={remarkFormStyle}>
          <div style={contentWrapper}>
            <div
              style={{ ...mapping["forms/label/1-default"], color: "white" }}
            >
              Remarks
            </div>
            <AutoSizeTextArea
              fieldstyle={{ ...MultilineInputFieldStyle }}
              value={this.state.content}
              CustomizedOnChange={(value) => {
                this.setState({ content: value });
              }}
            />
            <div style={buttonWrapper}>
              <ButtonSolid
                name={"Cancel"}
                height={"38px"}
                width={"120px"}
                size={"Small"}
                clickEvent={this.props.handleRemarkClose}
              />
              <ButtonSolid
                name={"Save"}
                height={"38px"}
                width={"120px"}
                size={"Small"}
                clickEvent={() => {
                  this.props.handleRemarkSubmit(this.state.content);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const MultilineInputFieldStyle = {
  padding: "4px",
  resize: "none",
  overflow: "hidden",
  height: "auto",
  border: "none",
  //outline:mapping['Field_Outline']+' solid '+borderColor,
  paddingLeft: mapping["Field_padding_Horizontal"],
  paddingRight: mapping["Field_padding_Horizontal"],
  borderRadius: 0,
  width: "100%",
  borderWidth: "0px",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  color: mapping["Color_Basic_Light"],
  outline: "none",
};
const contentWrapper = {
  width: "33.75rem",
  height: "fit-content",
  backgroundColor: "rgba(0,0,0,0.4)",
  padding: "1.2rem",
};
const remarkFormStyle = {
  position: "relative",
  left: "-50%",
};
const remarkFormWrapStyle = {
  position: "fixed",
  left: "50%",
  top: "13.3rem",
};

const buttonWrapper = {
  display: "inline-flex",
  paddingTop: "31px",
  width: "100%",
  justifyContent: "space-between",
};
export { Remarks };
