import React from "react";
import {
  IconSVG,
  mapping,
  Pill,
  ButtonSolid,
  Risk_Matrix_Compoenet_Block,
  Tab_BookMark,
  DropDownListButton,
  NameCard,
  FormatDate,
  AutoSizeTextArea,
  NonValidationInput,
  CustomDatePicker,
  IconButtonSolid,
} from "@aim-mf/styleguide";

const VersionButton = {
  width: "13.5rem",
  height: "2.75rem",
  backgroundColor: "#3D4657",
  borderRadius: "20.5px",
  display: "flex",
  position: "relative",
  zIndex: 1,
  marginBottom: "0.8rem",
  outline: "none",
};

const VersionButtonHover = {
  width: "13.5rem",
  height: "2.75rem",
  backgroundColor: "#3D4657",
  borderRadius: "20.5px",
  display: "flex",
  position: "relative",
  zIndex: 1,
  marginBottom: "0.8rem",
  border: "2px solid #00A2FF",
  outline: "none",
};
const SendButton = {
  color: "white",
  "background-color": "#6C757D",
  "border-radius": "5px",
  padding: "2px 8px 2px 8px",
  "font-size": 10,
};

class ControlSelfAssessmentDocumentRemediationPlans extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      remediationPlans: this.props.remediationPlans,
    };
  }

  render() {
    return (
      <div style={ControlDetailDropDownsWrapperStyle}>
        <div style={{ ...sectionTitleStyle, marginLeft: "20px" }}>
          Remediation Plan
        </div>
        {this.props.remediationPlans.map((remediationPlan, index) => {
          return (
            <RemediationPlanCard
              documentIndex={this.props.documentIndex}
              remediationPlan={remediationPlan}
              index={index}
              handleRemediationPlanChangeChange={
                this.handleRemediationPlanChangeChange
              }
              handleAddUser={this.props.handleAddUser}
              handleRPDelete={this.handleRPDelete}
            />
          );
        })}
        <div
          style={{
            height: "1.25rem",
            marginLeft: "1.25rem",
            marginRight: "1.3rem",
            backgroundColor: mapping["Color_BG_02_solid"],
          }}
        />
        <AddNew
          handleRemediationPlanAddNew={this.handleRemediationPlanAddNew}
        />
      </div>
    );
  }

  handleRemediationPlanAddNew = () => {
    let newRemediationPlan = this.state.remediationPlans;
    newRemediationPlan.push({
      id: Math.max(...newRemediationPlan.map((a) => a.id), 0) + 1,
      description: "",
      target_date: new Date(),
      owner: [],
      is_new: true,
    });
    this.setState({ testResult: newRemediationPlan }, () => {
      this.props.handleDocumentRemediationPlanChange(
        this.props.documentIndex,
        this.state.remediationPlans
      );
    });
  };

  handleRemediationPlanChangeChange = (index, remediationPlans) => {
    let newRemediationPlans = this.state.remediationPlans;
    newRemediationPlans[index] = remediationPlans;
    this.setState({ remediationPlans: newRemediationPlans }, () => {
      this.props.handleDocumentRemediationPlanChange(
        this.props.documentIndex,
        this.state.remediationPlans
      );
    });
  };
  handleRPDelete = (index) => {
    let newRemediationPlans = this.state.remediationPlans;
    newRemediationPlans.splice(index, 1);
    this.props.handleDocumentRemediationPlanChange(
      this.props.documentIndex,
      newRemediationPlans
    );
  };
}

const RemediationPlanCard = (props) => {
  const onRemediationPlanChange = (key, value) => {
    let remediationPlan = props.remediationPlan;
    remediationPlan[key] = value;
    props.handleRemediationPlanChangeChange(props.index, remediationPlan);
  };
  return (
    <div
      style={{
        position: "relative",
        display: "inline-flex",
        marginLeft: "20px",
        backgroundColor: mapping["Color_BG_02_solid"],
        paddingTop: "20px",
        paddingLeft: "20px",
      }}
    >
      <div
        className={"div_hover"}
        style={{ position: "absolute", top: "25px", right: "2px" }}
      >
        <IconSVG
          name={"delete"}
          color={mapping["Color_Basic_Mute_2"]}
          onClick={() => {
            props.handleRPDelete(props.index);
          }}
        />
      </div>
      <div>
        <PlanDescription
          description={props.remediationPlan.description}
          onRemediationPlanChange={onRemediationPlanChange}
        />
      </div>
      <div>
        <PlanTimeline
          value={props.remediationPlan.target_date}
          onRemediationPlanChange={onRemediationPlanChange}
        />
      </div>
      <div>
        <PlanOwner
          owner={props.remediationPlan.owner}
          index={props.index}
          documentIndex={props.documentIndex}
          handleAddUser={props.handleAddUser}
        />
      </div>
    </div>
  );
};

const PlanDescription = (props) => {
  const onCustomChange = (value) => {
    props.onRemediationPlanChange("description", value);
  };
  return (
    <div>
      <div style={{ ...sectionTitleStyle, width: "531px" }}>Description</div>
      <ContentTextInput
        width={"504px"}
        value={props.description}
        onCustomChange={onCustomChange}
      />
    </div>
  );
};

const PlanTimeline = (props) => {
  return (
    <div>
      <div style={{ ...sectionTitleStyle, width: "186px" }}>Target Date</div>
      <TargetDate
        value={props.value}
        onRemediationPlanChange={props.onRemediationPlanChange}
      />
    </div>
  );
};

const PlanOwner = (props) => {
  const OwnerChange = () => {
    props.handleAddUser(
      "RemediationPlanOwner",
      [props.documentIndex, props.index],
      props.owner,
      "Add Responsibility Owner"
    );
  };
  return (
    <div>
      <div style={{ ...sectionTitleStyle, width: "270px" }}>
        Responsibility Owner
      </div>
      {props.owner.map((owner) => {
        return (
          <NameCard
            initial={owner.display_name
              .split(" ")
              .map((a) => a[0])
              .join("")}
            name={owner.display_name}
            title={owner.title}
          />
        );
      })}
      <ButtonSolid
        name={"Add\u00a0\u00a0+"}
        fontStyle={mapping["Font_Button_Badge_White"]}
        height={"18px"}
        width={"52px"}
        size={"Small"}
        clickEvent={OwnerChange}
      />
    </div>
  );
};

const ContentTextInput = (props) => {
  const onChange = (value) => {
    props.onCustomChange(value, props.id);
  };
  return (
    <AutoSizeTextArea
      fieldstyle={{ ...InputFieldStyle, width: props.width }}
      value={props.value}
      CustomizedOnChange={onChange}
    />
  );
};

const TargetDate = (props) => {
  const CustomOnChange = (value) => {
    props.onRemediationPlanChange("target_date", value);
  };
  return (
    <CustomDatePicker
      width={145}
      CustomOnChange={CustomOnChange}
      value={props.value} //{}FormatDate(props.value, "DD/MM/YYYY")}
    />
  );
};

const AddNew = (props) => {
  const onClick = () => {
    props.handleRemediationPlanAddNew();
  };
  return (
    <div
      style={{
        display: "flex",
        backgroundColor: mapping["Color_BG_02_solid"],
        margin: "6px 20px 0 20px",
        padding: "16px",
      }}
    >
      <div style={{ margin: "auto" }}>
        <IconButtonSolid
          name={"Add"}
          icon={
            <IconSVG
              name={"newRisk"}
              color={"white"}
              size={"0.6"}
              wrapperStyle={{
                transformOrigin: "0 50% 0px",
              }}
            />
          }
          width={"64px"}
          height={"31px"}
          iconPos={"Left"}
          size={"Small"}
          clickEvent={onClick}
        />
      </div>
    </div>
  );
};
const sectionTitleStyle = {
  ...mapping["Sub Text Tag/H7-blue-tag"],
  color: "#00BEFF",
  paddingBottom: "1.25rem",
};

const TitleInputFieldStyle = {
  height: mapping["Field_Height"],
  width: "58.5rem",
  borderWidth: "0px",
  color: mapping["Color_Basic_Light"],
  ...sectionTitleStyle,
  backgroundColor: "transparent",
  outline: "none",
  border: "none",
  boxShadow: "none",
};
const InputFieldStyle = {
  height: mapping["Field_Height"],
  width: "63rem",
  borderWidth: "0px",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  border: "none !important",
  color: mapping["Color_Basic_Light"],
  padding: "4px",
  resize: "none",
  overflow: "hidden",
  //outline:mapping['Field_Outline']+' solid '+borderColor,
  paddingLeft: mapping["Field_padding_Horizontal"],
  paddingRight: mapping["Field_padding_Horizontal"],
  borderRadius: 0,
  outline: "none",
  marginBottom: "2.37rem",
};
const CardTitleDateStyle = {
  ...mapping["heading/h5/lightleft"],
  padding: "1rem 0 3rem 1rem",
};

const ControlDetailWrapperStyle = {
  width: "100%",
  height: "fit-content",
  marginTop: "5rem",
  marginLeft: "5rem",
  backgroundColor: mapping["Color_Form_05_Field_Disabled"],
};

const ControlDetailDropDownsWrapperStyle = {
  paddingBottom: "40px",
  paddingTop: "47px",
  width: "65.6rem",
  height: "fit-content",
  margin: "0 1.5rem 0 1.5rem ",
  backgroundColor: mapping["Color_BG_05_solid"],
};

const DropDownContentStyle = {
  width: "17rem",
  padding: "1rem 2.2rem 1rem 1rem",
};
const BasicInfoStlye = {
  width: "100%",
  height: "fit-content",
  paddingTop: "1rem",
  paddingLeft: "5rem",
};

const ShortTextStyle = {
  ...mapping["forms/input-small"],
  overflow: "hidden",
  height: "2rem",
  border: "none",
  //outline:mapping['Field_Outline']+' solid '+borderColor,
  padding: "9px 0 0 13px",
  borderRadius: 0,
  width: "9rem",
  borderWidth: "0px",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  color: mapping["Color_Basic_Light"],
};

const AttachmentsTitleStyle = {
  height: "17px",
  width: "76px",
  color: "#FFFFFF",
  fontFamily: "Open Sans",
  fontSize: "12px",
  fontWeight: "bold",
  letterSpacing: "-0.23px",
  lineHeight: "17px",
};

const ShortMultilineInputFieldStyle = {
  resize: "none",
  overflow: "hidden",
  height: "auto",
  border: "none",
  //outline:mapping['Field_Outline']+' solid '+borderColor,
  paddingLeft: mapping["Field_padding_Horizontal"],
  paddingRight: mapping["Field_padding_Horizontal"],
  borderRadius: 0,
  width: "20.875rem",
  borderWidth: "0px",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  color: mapping["Color_Basic_Light"],
};

const parentRiskStyle = {
  paddingTop: "0.2rem",
  ...mapping["symbols/breadcrumbs/active"],
  color: "#6C757D",
};

const riskStatusStyle = {
  paddingTop: "1.25rem",
};

const riskNameStyle = {
  paddingTop: "1rem",
  ...mapping["heading/h3/lightleft"],
};

const riskInfoSectionWrapperStyle = {
  width: "100%",
  height: "15rem",
  display: "inline-flex",
};

const descriptionWrapperStyle = {
  width: "25rem",
  height: "100%",
};
const riskOwnerWrapperStyle = {
  width: "15rem",
  height: "100%",
};
const riskDelegateWrapperStyle = {
  width: "15rem",
  height: "100%",
};
const riskRatingWrapperStyle = {
  width: "15rem",
  height: "15rem",
};
const riskVersionWrapperStyle = {
  width: "25rem",
  height: "100%",
  fontSize: 0,
  position: "relative",
};

const descriptionContentStyle = {
  ...mapping["paragraph/small/lightleft"],
};
const riskOwnerContentStyle = {
  display: "inline-flex",
  paddingBottom: "1.25rem",
};

const riskOwnerRoleStyle = {
  ...mapping["Font_Avatar_Role"],
  paddingTop: "0.2rem",
};

const riskRatingContentStyle = {
  transform: "scale(0.4)",
  transformOrigin: "top left",
  paddingTop: "1rem",
};

const RiskTIerWrapperStyle = {
  width: "25rem",
  height: "fit-content",
  paddingBottom: "3rem",
};

const riskTierDropDownButtonStyle = {
  width: "7rem",
  height: "2rem",
  backgroundColor: mapping["Color_Basic_Secondary"],
  border: "none",
  boxShadow: "none",
};
const versionButtonContentStyle = {
  display: "inline-flex",
  width: "fit-content",
  margin: "auto",
  height: "fit-content",
};
const versionButtonLeftIconStyle = {
  backgroundColor: mapping["Color_Basic_Secondary"],
  borderRadius: "50%",
  width: "2.375rem",
  height: "2.375rem",
};
const versionButtonNameStyle = {
  textAlign: "left",
  ...mapping["Font_Version_Name"],
  lineHeight: "17px",
};
const versionButtonDateStyle = {
  ...mapping["Font_Version_Date"],
  width: "50%",
  textAlign: "left",
  display: "inline-flex",
};
const versionButtonStatusStyle = {
  ...mapping["Font_Version_Status"],
  width: "50%",
  textAlign: "left",
  display: "inline-flex",
};
const versionDotLineStyle = {
  top: "4rem",
  marginLeft: "22.5px",
  height: "14rem",
  display: "inline-block",
  borderStyle: "dashed",
  borderLeftWidth: "1px",
  zIndex: "0",
  position: "absolute",
  borderColor: "rgba(181,198,209,0.5)",
};
const versionButtonViewMoreWrapperStyle = {
  marginLeft: "18px",
  fontSize: 0,
  position: "absolute",
  top: "18rem",
};
const versionDotCircleStyle = {
  height: "10px",
  width: "10px",
  backgroundColor: "283244",
  borderRadius: "50%",
  border: "solid",
  borderColor: "#6C7A88",
  display: "inline-block",
};
const versionButtonViewMoreStyle = {
  padding: "0px",
  textAlign: "top",
  marginLeft: 6,
  marginBottom: 0,
  position: "relative",
  top: -3,
  zIndex: 2,
  display: "inline-block",
  textDecoration: "underline",
  outline: "none",
  height: "16px",
  width: "57px",
  color: "#00BEFF",
  fontFamily: "Roboto",
  fontSize: "12px",
  fontWeight: 500,
  letterSpacing: 0,
  lineHheight: "16px",
};
export { ControlSelfAssessmentDocumentRemediationPlans };
