import React from "react";
import {
  IconSVG,
  mapping,
  Pill,
  ButtonSolid,
  Risk_Matrix_Compoenet_Block,
  Tab_BookMark,
  DropDownListButton,
  NameCard,
  FormatDate,
  AutoSizeTextArea,
} from "@aim-mf/styleguide";
import { navigateToUrl } from "single-spa";

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      control: this.props.control,
    };
  }

  render() {
    if (!this.props.risk.name) {
      return <div />;
    }
    return (
      <div name={"SideBar"} style={SideBarStyle}>
        <div name={"sidebar-title"} style={titleCategoryFont}>
          {this.props.risk.category.toUpperCase()}
        </div>
        <ul name={"sidebar-menu"}>
          <li
            className={"div_hover"}
            style={
              this.props.risk.id === this.state.hoverID
                ? MenuWrapperHover
                : MenuWrapper
            }
            onMouseEnter={() => {
              this.setState({ hoverID: this.props.risk.id });
            }}
            onMouseLeave={() => {
              this.setState({ hoverID: -1 });
            }}
          >
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
              style={parentMenuFont}
              onClick={() => {
                navigateToUrl("/risk-edit/" + this.props.risk.id + "/");
              }}
            >
              <IconSVG name={"work"} color={"white"} size={"0.6"} />
              {this.props.risk.name}
            </div>
          </li>
          {this.props.risk.controls
            .filter((control) => {
              return control.control_type === "Manual";
            })
            .map((control) => {
              return (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <li
                  className={"div_hover"}
                  key={control.id}
                  style={
                    control.id === this.props.control.id ||
                    control.id === this.state.hoverID
                      ? MenuWrapperHover
                      : MenuWrapper
                  }
                  onMouseEnter={() => {
                    this.setState({ hoverID: control.id });
                  }}
                  onMouseLeave={() => {
                    this.setState({ hoverID: -1 });
                  }}
                  onClick={() => {
                    navigateToUrl(
                      "/control-self-assessment/" + control.id + "/"
                    );
                    this.props.navigateToOtherControl(control.id);
                  }}
                >
                  {this.props.control.id === control.id && (
                    <div style={indicatorBar} />
                  )}
                  <div style={subMenuFont}>
                    &#11172;&nbsp;&nbsp;&nbsp;{control.description}
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    );
  }
}

const SideBarStyle = {
  width: "15.6875rem",
  backgroundColor: "#131821",
};

const titleCategoryFont = {
  height: "24px",
  width: "203px",
  color: "#FFFFFF",
  fontFamily: "Roboto",
  fontSize: "18px",
  fontWeight: 500,
  letterSpacing: "0.3px",
  lineHeight: "24px",
  marginTop: "2.56rem",
  marginBottom: "2.15rem",
  marginLeft: "1.125rem",
};

const subMenuFont = {
  ...mapping["paragraph/small/lightleft"],
  display: "flex",
  alignItems: "center",
  marginLeft: "1.4rem",
};
const parentMenuFont = {
  color: "#FFFFFF",
  fontFamily: "Roboto",
  fontSize: "13px",
  fontWeight: "bold",
  letterSpacing: 0,
  lineHeight: "20px",
  display: "flex",
  alignItems: "center",
  marginLeft: "1rem",
};

const MenuWrapper = {
  borderLeft: "transparent solid 4px",
  minHeight: "2.625rem",
  height: "fit-content",
  backgroundColor: "#131821",
  borderBottom: "1px solid #232C3D",
};
const MenuWrapperHover = {
  borderLeft: mapping["Color_Basic_Primary"] + " solid 4px",
  minHeight: "2.625rem",
  height: "fit-content",
  backgroundColor: "#232C3D",
  borderBottom: "1px solid #232C3D",
};
const indicatorBar = {
  position: "absolute",
  width: "6px",
  height: "inherent",
  backgroundColor: mapping["Color_Basic_Primary"],
};
export { SideBar };
