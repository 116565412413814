import React from "react";
import { mapping } from "@aim-mf/styleguide";
import { ControlSelfAssessmentDocumentAssessment } from "./CSADocumentAssessment";
import { ControlSelfAssessmentDocumentRemediationPlans } from "./CSADocumentRemediationPlans";
import { ControlSelfAssessmentDocumentTestResults } from "./CSADocumentTestResults";
const VersionButton = {
  width: "13.5rem",
  height: "2.75rem",
  backgroundColor: "#3D4657",
  borderRadius: "20.5px",
  display: "flex",
  position: "relative",
  zIndex: 1,
  marginBottom: "0.8rem",
  outline: "none",
};

class ControlSelfAssessmentDocument extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    //console.log(this.props.documents)
    const RiskStatus = "Working Draft";
    const RiskStatusBGColor = "#FDD60066";

    return (
      <div style={ControlDetailWrapperStyle}>
        <div
          style={{
            ...mapping["heading/h5/lightleft"],
            padding: "17px 0 0 17px",
          }}
        >
          Control Self Assessment Documentation
        </div>

        {this.props.documents.map((document, index) => {
          return (
            <div key={this.props.documents.id}>
              <ControlSelfAssessmentDocumentAssessment
                document={document}
                documentIndex={index}
                handleDocumentsChange={this.handleDocumentsChange}
                handleAddUser={this.props.handleAddUser}
              />
              <ControlSelfAssessmentDocumentTestResults
                documentIndex={index}
                handleRemarkOpen={this.props.handleRemarkOpen}
                testResult={document.test_result}
                handleDocumentTestResultChange={
                  this.handleDocumentTestResultChange
                }
              />
              <ControlSelfAssessmentDocumentRemediationPlans
                documentIndex={index}
                remediationPlans={document.remediation_plan}
                handleDocumentRemediationPlanChange={
                  this.handleDocumentRemediationPlanChange
                }
                handleAddUser={this.props.handleAddUser}
              />
            </div>
          );
        })}
      </div>
    );
  }

  handleDocumentsChange = (index, key, value) => {
    let newDocument = this.props.documents;
    newDocument[index][key] = value;
    this.setState({ documents: newDocument }, () => {
      this.props.handleDocumentChange(this.props.documents);
    });
  };

  handleDocumentTestResultChange = (index, testResult) => {
    let newDocument = this.props.documents;
    newDocument[index].test_result = testResult;
    this.props.handleDocumentChange(newDocument);
  };

  handleDocumentRemediationPlanChange = (index, remediationPlans) => {
    let newDocument = this.props.documents;
    newDocument[index].remediation_plan = remediationPlans;
    this.props.handleDocumentChange(newDocument);
  };
  handledelegateChange = () => {
    this.props.handleAddUser("delegate", -1, this.props.riskDetail.delegate);
  };
}

const sectionTitleStyle = {
  ...mapping["Sub Text Tag/H7-blue-tag"],
  color: "#00BEFF",
  paddingBottom: "1.25rem",
};

const TitleInputFieldStyle = {
  height: mapping["Field_Height"],
  width: "58.5rem",
  borderWidth: "0px",
  color: mapping["Color_Basic_Light"],
  ...sectionTitleStyle,
  backgroundColor: "transparent",
  outline: "none",
  border: "none",
  boxShadow: "none",
};
const InputFieldStyle = {
  height: mapping["Field_Height"],
  width: "63rem",
  borderWidth: "0px",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  border: "none !important",
  color: mapping["Color_Basic_Light"],
  padding: "4px",
  resize: "none",
  overflow: "hidden",
  //outline:mapping['Field_Outline']+' solid '+borderColor,
  paddingLeft: mapping["Field_padding_Horizontal"],
  paddingRight: mapping["Field_padding_Horizontal"],
  borderRadius: 0,
  outline: "none",
  marginLeft: "0.75rem",
  marginBottom: "2.37rem",
};
const CardTitleDateStyle = {
  ...mapping["heading/h5/lightleft"],
  padding: "1rem 0 3rem 1rem",
};

const ControlDetailWrapperStyle = {
  paddingBottom: "40px",
  width: "100%",
  height: "fit-content",
  marginTop: "5rem",
  marginLeft: "5rem",
  backgroundColor: mapping["Color_BG_03_trans"],
};

const ControlDetailDropDownsWrapperStyle = {
  width: "65.6rem",
  height: "fit-content",
  margin: "1.5rem",
  backgroundColor: "rgba(62,78,108,0.5)",
};

const DropDownContentStyle = {
  width: "17rem",
  padding: "1rem 2.2rem 1rem 1rem",
};
const BasicInfoStlye = {
  width: "100%",
  height: "fit-content",
  paddingTop: "1rem",
  paddingLeft: "5rem",
};

const ShortTextStyle = {
  ...mapping["forms/input-small"],
  overflow: "hidden",
  height: "2rem",
  border: "none",
  //outline:mapping['Field_Outline']+' solid '+borderColor,
  padding: "9px 0 0 13px",
  borderRadius: 0,
  width: "9rem",
  borderWidth: "0px",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  color: mapping["Color_Basic_Light"],
};

const AttachmentsTitleStyle = {
  height: "17px",
  width: "76px",
  color: "#FFFFFF",
  fontFamily: "Open Sans",
  fontSize: "12px",
  fontWeight: "bold",
  letterSpacing: "-0.23px",
  lineHeight: "17px",
};

const ShortMultilineInputFieldStyle = {
  resize: "none",
  overflow: "hidden",
  height: "auto",
  border: "none",
  //outline:mapping['Field_Outline']+' solid '+borderColor,
  paddingLeft: mapping["Field_padding_Horizontal"],
  paddingRight: mapping["Field_padding_Horizontal"],
  borderRadius: 0,
  width: "20.875rem",
  borderWidth: "0px",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  color: mapping["Color_Basic_Light"],
};

const parentRiskStyle = {
  paddingTop: "0.2rem",
  ...mapping["symbols/breadcrumbs/active"],
  color: "#6C757D",
};

const riskStatusStyle = {
  paddingTop: "1.25rem",
};

const riskNameStyle = {
  paddingTop: "1rem",
  ...mapping["heading/h3/lightleft"],
};

const riskInfoSectionWrapperStyle = {
  width: "100%",
  height: "15rem",
  display: "inline-flex",
};

const descriptionWrapperStyle = {
  width: "25rem",
  height: "100%",
};
const riskOwnerWrapperStyle = {
  width: "15rem",
  height: "100%",
};
const riskDelegateWrapperStyle = {
  width: "15rem",
  height: "100%",
};
const riskRatingWrapperStyle = {
  width: "15rem",
  height: "15rem",
};
const riskVersionWrapperStyle = {
  width: "25rem",
  height: "100%",
  fontSize: 0,
  position: "relative",
};

const descriptionContentStyle = {
  ...mapping["paragraph/small/lightleft"],
};
const riskOwnerContentStyle = {
  display: "inline-flex",
  paddingBottom: "1.25rem",
};

const riskOwnerRoleStyle = {
  ...mapping["Font_Avatar_Role"],
  paddingTop: "0.2rem",
};

const riskRatingContentStyle = {
  transform: "scale(0.4)",
  transformOrigin: "top left",
  paddingTop: "1rem",
};

const RiskTIerWrapperStyle = {
  width: "25rem",
  height: "fit-content",
  paddingBottom: "3rem",
};

const riskTierDropDownButtonStyle = {
  width: "7rem",
  height: "2rem",
  backgroundColor: mapping["Color_Basic_Secondary"],
  border: "none",
  boxShadow: "none",
};
const versionButtonContentStyle = {
  display: "inline-flex",
  width: "fit-content",
  margin: "auto",
  height: "fit-content",
};
const versionButtonLeftIconStyle = {
  backgroundColor: mapping["Color_Basic_Secondary"],
  borderRadius: "50%",
  width: "2.375rem",
  height: "2.375rem",
};
const versionButtonNameStyle = {
  textAlign: "left",
  ...mapping["Font_Version_Name"],
  lineHeight: "17px",
};
const versionButtonDateStyle = {
  ...mapping["Font_Version_Date"],
  width: "50%",
  textAlign: "left",
  display: "inline-flex",
};
const versionButtonStatusStyle = {
  ...mapping["Font_Version_Status"],
  width: "50%",
  textAlign: "left",
  display: "inline-flex",
};
const versionDotLineStyle = {
  top: "4rem",
  marginLeft: "22.5px",
  height: "14rem",
  display: "inline-block",
  borderStyle: "dashed",
  borderLeftWidth: "1px",
  zIndex: "0",
  position: "absolute",
  borderColor: "rgba(181,198,209,0.5)",
};
const versionButtonViewMoreWrapperStyle = {
  marginLeft: "18px",
  fontSize: 0,
  position: "absolute",
  top: "18rem",
};
const versionDotCircleStyle = {
  height: "10px",
  width: "10px",
  backgroundColor: "283244",
  borderRadius: "50%",
  border: "solid",
  borderColor: "#6C7A88",
  display: "inline-block",
};
const versionButtonViewMoreStyle = {
  padding: "0px",
  textAlign: "top",
  marginLeft: 6,
  marginBottom: 0,
  position: "relative",
  top: -3,
  zIndex: 2,
  display: "inline-block",
  textDecoration: "underline",
  outline: "none",
  height: "16px",
  width: "57px",
  color: "#00BEFF",
  fontFamily: "Roboto",
  fontSize: "12px",
  fontWeight: 500,
  letterSpacing: 0,
  lineHheight: "16px",
};
export { ControlSelfAssessmentDocument };
