import React from "react";
import {
  mapping,
  Pill,
  ButtonSolid,
  NameCard,
  FormatDate,
  AutoSizeTextArea,
  CustomizedCheckBox,
} from "@aim-mf/styleguide";

class ControlSelfAssessmentDocumentAssessment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      control: this.props.control,
    };
  }

  render() {
    return (
      <div style={ControlDetailDropDownsWrapperStyle}>
        <div style={completeCheckBoxStyle}>
          <CustomizedCheckBox
            value={this.props.document.completed}
            checkBoxDivStyle={{ lineHeight: 0 }}
            label={"Mark as Completed"}
            onCheckChange={() => {
              this.props.handleDocumentsChange(
                this.props.documentIndex,
                "completed",
                !this.props.document.completed
              );
            }}
          />
        </div>
        <div style={CardTitleDateStyle}>
          {FormatDate(this.props.document.createdDatetime, "DD MMM YYYY")}
        </div>
        <div style={{ display: "inline-flex" }}>
          <ShortText
            shortText={this.props.document.assessment_criteria}
            index={this.props.documentIndex}
            handleDocumentsChange={this.props.handleDocumentsChange}
          />
          <div style={{ width: "2.125rem" }} />
          <ProcessOwner
            owner={this.props.document.owner}
            index={this.props.documentIndex}
            handleDocumentsChange={this.props.handleDocumentsChange}
            handleAddUser={this.props.handleAddUser}
          />
          <div style={{ width: "3.125rem" }} />
          <Assessment
            assessment={this.props.document.assessment}
            index={this.props.documentIndex}
            handleDocumentsChange={this.props.handleDocumentsChange}
          />
        </div>
        <Comment
          comment={this.props.document.comments}
          index={this.props.documentIndex}
          handleDocumentsChange={this.props.handleDocumentsChange}
        />
        ;
        <div
          style={{
            margin: "auto",
            width: "fit-content",
            paddingBottom: "2rem",
          }}
        ></div>
      </div>
    );
  }
}

const ShortText = (props) => {
  const onChange = (value) => {
    props.handleDocumentsChange(props.index, "assessment_criteria", value);
  };
  return (
    <div style={{ marginLeft: "1.25rem" }}>
      <div style={sectionTitleStyle}>Assessment Criteria</div>
      <ContentTextInput
        width={"23.3rem"}
        value={props.shortText}
        onCustomChange={onChange}
      />
    </div>
  );
};
const ProcessOwner = (props) => {
  const OwnerChange = () => {
    props.handleAddUser(
      "ProcessOwner",
      props.index,
      props.owner,
      "Add Process Owner"
    );
  };
  return (
    <div>
      <div style={sectionTitleStyle}>Process Owner</div>
      {props.owner.map((owner) => {
        return (
          <NameCard
            initial={owner.display_name
              .split(" ")
              .map((a) => a[0])
              .join("")}
            name={owner.display_name}
            title={owner.title}
          />
        );
      })}
      <ButtonSolid
        name={"Add\u00a0\u00a0+"}
        fontStyle={mapping["Font_Button_Badge_White"]}
        height={"18px"}
        width={"52px"}
        size={"Small"}
        clickEvent={OwnerChange}
      />
    </div>
  );
};
const Assessment = (props) => {
  return (
    <div>
      <div style={sectionTitleStyle}>Process Owner's Assessment</div>
      <div style={{ display: "inline-flex" }}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          onClick={() => {
            props.handleDocumentsChange(props.index, "assessment", "Good");
          }}
        >
          <Pill
            pillStyle={{ marginRight: "1rem" }}
            contentStyle={{
              color: props.assessment === "Good" ? "#FFFFFFD0" : "#FFFFFF3B",
            }}
            content={"Good"}
            color={
              mapping["Color_Platform_RiskLow"] +
              (props.assessment === "Good" ? "66" : "3B")
            }
          />
        </div>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          onClick={() => {
            props.handleDocumentsChange(
              props.index,
              "assessment",
              "Need Improvement"
            );
          }}
        >
          <Pill
            pillStyle={{ marginRight: "1rem" }}
            contentStyle={{
              color:
                props.assessment === "Need Improvement"
                  ? "#FFFFFFD0"
                  : "#FFFFFF3B",
            }}
            content={"Need Improvement"}
            color={
              mapping["Color_Platform_RiskMed"] +
              (props.assessment === "Need Improvement" ? "66" : "3B")
            }
          />
        </div>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          onClick={() => {
            props.handleDocumentsChange(
              props.index,
              "assessment",
              "Not Present"
            );
          }}
        >
          <Pill
            contentStyle={{
              color:
                props.assessment === "Not Present" ? "#FFFFFFD0" : "#FFFFFF3B",
            }}
            content={"Not Present"}
            color={
              mapping["Color_Platform_RiskHigh"] +
              (props.assessment === "Not Present" ? "66" : "3B")
            }
          />
        </div>
      </div>
    </div>
  );
};
const Comment = (props) => {
  const onChange = (value) => {
    props.handleDocumentsChange(props.index, "comments", value);
  };
  return (
    <div
      style={{
        marginLeft: "1.25rem",
        marginBottom: "2rem",
        marginTop: "110px",
      }}
    >
      <div style={sectionTitleStyle}>Process owner’s comments</div>
      <ContentTextInput
        width={"63.125rem"}
        value={props.comment}
        onCustomChange={onChange}
      />
    </div>
  );
};

const ContentTextInput = (props) => {
  const onChange = (value) => {
    props.onCustomChange(value, props.id);
  };
  return (
    <AutoSizeTextArea
      fieldstyle={{ ...InputFieldStyle, width: props.width }}
      value={props.value}
      CustomizedOnChange={onChange}
    />
  );
};

const sectionTitleStyle = {
  ...mapping["Sub Text Tag/H7-blue-tag"],
  color: "#00BEFF",
  paddingBottom: "1.25rem",
};

const TitleInputFieldStyle = {
  height: mapping["Field_Height"],
  width: "58.5rem",
  borderWidth: "0px",
  color: mapping["Color_Basic_Light"],
  ...sectionTitleStyle,
  backgroundColor: "transparent",
  outline: "none",
  border: "none",
  boxShadow: "none",
  paddingLeft: 0,
};
const InputFieldStyle = {
  height: mapping["Field_Height"],
  width: "63rem",
  borderWidth: "0px",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  border: "none !important",
  color: mapping["Color_Basic_Light"],
  padding: "4px",
  resize: "none",
  overflow: "hidden",
  //outline:mapping['Field_Outline']+' solid '+borderColor,
  paddingLeft: mapping["Field_padding_Horizontal"],
  paddingRight: mapping["Field_padding_Horizontal"],
  borderRadius: 0,
  outline: "none",
};
const CardTitleDateStyle = {
  ...mapping["heading/h5/lightleft"],
  padding: "1rem 0 3rem 1rem",
};

const ControlDetailDropDownsWrapperStyle = {
  position: "relative",
  width: "65.6rem",
  height: "fit-content",
  margin: "1.5rem",
  marginBottom: 0,
  backgroundColor: mapping["Color_BG_05_solid"],
};

const completeCheckBoxStyle = {
  position: "absolute",
  right: "15px",
  top: "15px",
  color: "white",
};
export { ControlSelfAssessmentDocumentAssessment };
