import React from "react";
import { ControlBasicInfo } from "./component/controlBasicInfo";
import { ControlDetail } from "./component/controlDetail";
import { ControlSelfAssessmentDocument } from "./component/CSADocument";
import {
  AddUser,
  ButtonSolid,
  ConfirmationPopup,
  Loading,
  mapping,
} from "@aim-mf/styleguide";
import { SideBar } from "./component/sideBar";
import { Remarks } from "./component/remarks";

let controlDetailDropdownlistItem = {
  type: ["Detective", "Preventive", "Corrective"],
  antifraud: ["Yes", "No"],
  manual_system: ["Manual", "System"],
};

const ControlSelfAssessmentContentWrapperStyle = {
  height: "fit-content",
  width: "68.75rem",
};

class ControlSelfAssessment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      controlCSA: JSON.parse(JSON.stringify(this.props.controlCSA)),
      control: JSON.parse(JSON.stringify(this.props.control)),
      currentUser: {},
      AddUser: false,
      remark: { display: false },
      createTask: false,
      Confirmation: { display: false },
      title: "",
      uploadingFile: [],
      removeFile: [],
    };
  }

  decodeUserDetail = (field, controlCSADetail) => {
    let newControlCSADetail1 = controlCSADetail;
    if (field === "owner") {
      newControlCSADetail1.owner.forEach((username, index) => {
        let ownerDetail = this.props.userList.filter((user) => {
          return user.username === username;
        });
        newControlCSADetail1.owner[index] = ownerDetail[0];
      });
    } else if (field === "remediation_plan") {
      newControlCSADetail1.document.forEach((newControlCSADetail1) => {
        newControlCSADetail1.remediation_plan.forEach((item) => {
          item.owner.forEach((username, index) => {
            let ownerdetail = this.props.userList.filter((user) => {
              return user.username === username;
            });
            item.owner[index] = ownerdetail[0];
          });
        });
      });
    } else {
      newControlCSADetail1[field].forEach((item) => {
        item.owner.forEach((username, index) => {
          let ownerdetail = this.props.userList.filter((user) => {
            return user.username === username;
          });
          item.owner[index] = ownerdetail[0];
        });
      });
    }
    return newControlCSADetail1;
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (
      JSON.stringify(nextProps.control) !==
        JSON.stringify(this.props.control) &&
      Object.keys(nextProps.control).length !== 0
    ) {
      let newControlDetail = JSON.parse(JSON.stringify(nextProps.control));
      newControlDetail.owner.forEach((username, index) => {
        newControlDetail.owner[index] = this.props.userList.filter((user) => {
          return user.username === username;
        })[0];
      });
      //console.log(newControlDetail);
      this.setState({ control: newControlDetail });
    }

    if (
      JSON.stringify(nextProps.controlCSA) !==
        JSON.stringify(this.props.controlCSA) &&
      Object.keys(nextProps.controlCSA).length !== 0
    ) {
      let newControlCSADetail = JSON.parse(
        JSON.stringify(nextProps.controlCSA)
      );
      newControlCSADetail = this.decodeUserDetail(
        "document",
        newControlCSADetail
      );
      newControlCSADetail = this.decodeUserDetail(
        "remediation_plan",
        newControlCSADetail
      );
      this.setState({ controlCSA: newControlCSADetail });
    }
  }

  render() {
    // eslint-disable-next-line no-console
    //console.log(this.state.controlCSA);
    if (
      Object.keys(this.state.control).length === 0 ||
      Object.keys(this.state.controlCSA).length === 0
    ) {
      return <div />;
    }
    return (
      <div id={"control-self-assessment-wrapper"}>
        <div
          style={Object.assign(
            {},
            ControlCSASideStyle,
            this.state.AddUser ||
              this.state.createTask ||
              this.state.Confirmation.display ||
              this.state.remark.display ||
              this.props.loading
              ? BlurEffectStyle
              : { dummy: "dummy" }
          )}
        >
          <SideBar
            risk={this.props.risk}
            control={this.state.control}
            navigateToOtherControl={this.props.navigateToOtherControl}
          />
          <div
            id={"control-self-assessment-content-wrapper"}
            style={ControlSelfAssessmentContentWrapperStyle}
          >
            <ControlBasicInfo
              control={this.state.control}
              refPolicy={this.state.controlCSA.reference_policy}
              attachment={this.state.controlCSA.attachment}
              uploadingFile={this.state.uploadingFile}
              handleAttachmentDownload={this.props.handleAttachmentDownload}
              handleRefPolicyChange={this.handleRefPolicyChange}
              handleUploadFile={this.handleUploadFile}
              handleRemoveUploadFile={this.handleRemoveUploadFile}
              handleRemoveFile={this.handleRemoveFile}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "inherit",
                marginTop: "80px",
              }}
            >
              <ButtonSolid
                name={"Submit Change"}
                color={"Primary"}
                clickEvent={() => {
                  this.handleConfirmation(
                    "Submit CSA Changes",
                    "You are about to submit the CSA changes.",
                    this.submitChange
                  );
                }}
                size={"Small"}
                height={"31px"}
                width={"127px"}
              />
            </div>
            <ControlDetail
              controlDetail={this.state.controlCSA}
              controlDetailDropdownlistItem={controlDetailDropdownlistItem}
              handleControlDetailChange={this.handleControlDetailChange}
            />
            <ControlSelfAssessmentDocument
              documents={this.state.controlCSA.document}
              handleRemarkOpen={this.handleRemarkOpen}
              handleControlAssessmentChange={this.handleControlAssessmentChange}
              handleDocumentChange={this.handleDocumentChange}
              handleAddUser={this.handleAddUser}
            />
          </div>
        </div>
        {this.state.AddUser && (
          <AddUser
            title={this.state.title}
            userList={this.props.userList}
            currentUser={this.state.currentUser}
            handleAddUserClose={this.handleAddUserClose}
            handleAddUserSubmit={this.handleAddUserSubmit}
          />
        )}
        {this.state.remark.display && (
          <Remarks
            content={this.state.remark.content}
            handleRemarkClose={this.handleRemarkClose}
            handleRemarkSubmit={this.handleRemarkSubmit}
          />
        )}
        {this.state.Confirmation.display && (
          <ConfirmationPopup
            title={this.state.Confirmation.title}
            content={this.state.Confirmation.content}
            cancelFunction={this.state.Confirmation.cancelFunction}
            confirmFunction={this.state.Confirmation.confirmFunction}
          />
        )}
        {this.props.loading && (
          <Loading type={"bars"} color={mapping["Color_Basic_Primary"]} />
        )}
      </div>
    );
  }

  submitChange = () => {
    let newPackage = JSON.parse(JSON.stringify(this.state.controlCSA));
    delete newPackage.id;
    newPackage.document.forEach((document) => {
      delete document.createdDatetime;
      document.remediation_plan.forEach((remediation_plan) => {
        remediation_plan.owner = remediation_plan.owner.map((a) => a.username);
        remediation_plan.target_date = remediation_plan.target_date
          .toString()
          .replace("Z", "");
      });
      document.owner = document.owner.map((a) => a.username);
    });
    //console.log(newPackage)
    this.props.handleUpdateCSASubmit({ data: newPackage });
    if (this.state.uploadingFile.length > 0) {
      this.props.handleAttachmentUpload(this.state.uploadingFile);
    }
    if (this.state.removeFile.length > 0) {
      this.props.handleAttachmentRemove(this.state.removeFile);
    }
    this.setState({ uploadingFile: [], removeFile: [] });
  };

  handleAddUser = (field, index, currentUser, title) => {
    this.setState({
      AddUser: true,
      title: title,
      currentUser: { field: field, index: index, currentUser: currentUser },
    });
  };

  handleControlDetailChange = (key, value) => {
    let newControlCSA = this.state.controlCSA;
    newControlCSA[key] = value;
    this.setState({ controlCSA: newControlCSA });
  };

  handleControlAssessmentChange = (index, value) => {
    let newControlCSA = this.state.controlCSA;
    newControlCSA.documents[index]["assessment"] = value;
    this.setState({ controlCSA: newControlCSA });
  };

  handleDocumentChange = (documents) => {
    let newControlCSA = this.state.controlCSA;
    newControlCSA.document = documents;
    this.setState({ controlCSA: newControlCSA });
  };
  handleRefPolicyChange = (RefPolicy) => {
    let newControlCSA = this.state.controlCSA;
    newControlCSA.reference_policy = RefPolicy;
    this.setState({ controlCSA: newControlCSA });
  };

  handleAddUserClose = () => {
    this.setState({ AddUser: false, currentUser: {} });
  };
  handleAddUserSubmit = (owner) => {
    let newControlCSA = this.state.controlCSA;
    if (this.state.currentUser.field === "ProcessOwner") {
      newControlCSA.document[this.state.currentUser.index].owner = owner;
    } else if (this.state.currentUser.field === "RemediationPlanOwner") {
      let documentIndex = this.state.currentUser.index[0];
      let remediationPlanIndex = this.state.currentUser.index[1];
      //console.log(newControlCSA);
      newControlCSA.document[documentIndex]["remediation_plan"][
        remediationPlanIndex
      ].owner = owner;
    }
    this.setState({ AddUser: false, currentUser: {} });
  };
  handleRemarkOpen = (documentIndex, index) => {
    this.setState({
      remark: {
        display: true,
        documentIndex: documentIndex,
        index: index,
        content: this.state.controlCSA.document[documentIndex].test_result[
          index
        ].remarks,
      },
    });
  };

  handleRemarkClose = () => {
    this.setState({ remark: { display: false } });
  };
  handleRemarkSubmit = (content) => {
    let newControlCSA = this.state.controlCSA;
    newControlCSA.document[this.state.remark.documentIndex].test_result[
      this.state.remark.index
    ].remarks = content;
    this.setState({ remark: { display: false } });
  };
  handleUploadFile = (file) => {
    let uploadedFile = this.state.uploadingFile;
    uploadedFile.push(file);
    this.setState({ uploadedFile: uploadedFile });
  };
  handleRemoveUploadFile = (index) => {
    let uploadingFile = this.state.uploadingFile;
    uploadingFile.splice(index, 1);
    this.setState({ uploadingFile: uploadingFile });
  };
  handleRemoveFile = (id) => {
    let removeFile = this.state.removeFile;
    removeFile.push(parseInt(id));
    let newControlCSA = this.state.controlCSA;
    newControlCSA.attachment = newControlCSA.attachment.filter((attachment) => {
      return attachment.id !== id;
    });
    this.setState({ removeFile: removeFile, controlCSA: newControlCSA });
  };
  handleConfirmation = (title, content, confirmFunction) => {
    const confirmationFunction = () => {
      confirmFunction();
      this.setState({
        Confirmation: { display: false },
      });
    };
    const cancelFunction = () => {
      this.setState({
        Confirmation: { display: false },
      });
    };
    this.setState({
      Confirmation: {
        display: true,
        title: title,
        content: content,
        confirmFunction: confirmationFunction,
        cancelFunction: cancelFunction,
      },
    });
  };
}

const ControlCSASideStyle = {
  height: "fit-content",
  width: "120rem",
  display: "inline-flex",
};
const BlurEffectStyle = {
  filter: "blur(10px)",
};
export { ControlSelfAssessment };
